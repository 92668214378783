<template>
    <div>
      <vs-popup :fullscreen="tillSales.length > 0" title="Cierre de Caja - Resumen" :active.sync="isPopupActiveLocal" >
        <div class="container" style="margin: 0 auto" id="printArea">
            <vs-row v-if="tillSales.length > 0" vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" style="margin-bottom: 1em">
                <div class="w-1/3">
                    <span><strong>Usuario Inicio:</strong> {{ tillSales[0].name_open }} </span>
                </div>
                <div class="w-1/3">
                    <span><strong>Fecha Inicio:</strong> {{ tillSales[0].fecha_inicio }} </span>
                </div>
                <div class="w-1/3">
                    <span><strong>Turno:</strong> {{ tillSales[0].id_till_init }} </span>
                </div>
            </vs-row>
            <vs-row v-if="tillSales.length > 0" vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" style="margin-bottom: 1em">
                <div class="w-1/3">
                    <span><strong>Usuario Cierre:</strong> {{ tillSales[0].name_close }} </span>
                </div>
                <div class="w-1/3">
                    <span><strong>Fecha Cierre:</strong> {{ tillSales[0].fecha_cierre }} </span>
                </div>
                <div class="w-1/3">
                    <!-- <span><strong>Turno:</strong> {{ tillSales[0].id_till_init }} </span> -->
                </div>
            </vs-row>
            <!-- Sales by type -->
            <div v-if="tillSales.length > 0" class="vx-col sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mb-4" style="float: left">
                
                <vs-row>
                    <vs-table :model="this.tillSales[0]" ref="table" :data="tillSales[0]">  
                        <!-- <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                            <h2>Resumen de Ventas</h2>
                        </div> -->
                        <template slot="thead">
                            <vs-th sort-key="id">Resumen Caja</vs-th>
                            <vs-th sort-key="name">Total</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <tbody>
                                <vs-tr>
                                    <vs-td><p class="font-medium">Inicio de Caja</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.inicio_caja }}</p></vs-td>
                                </vs-tr>
                                <vs-tr v-if="data.venta_tiptap > 0">
                                    <vs-td><p class="font-medium">Ventas Tiptap</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.venta_tiptap }}</p></vs-td>
                                </vs-tr>
                                <vs-tr v-if="data.venta_tarjeta > 0">
                                    <vs-td><p class="font-medium">Ventas Tarjeta</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.venta_tarjeta }}</p></vs-td>
                                </vs-tr>
                                <vs-tr v-if="data.venta_efectivo > 0">
                                    <vs-td><p class="font-medium">Ventas Efectivo</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.venta_efectivo }}</p></vs-td>
                                </vs-tr>
                                <vs-tr v-if="data.venta_courtesia">
                                    <vs-td><p class="font-medium">Cortesía</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.venta_courtesia }}</p></vs-td>
                                </vs-tr>
                                <vs-tr v-if="data.venta_recargas">
                                    <vs-td><p class="font-medium">Recarga en Efectivo</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.venta_recargas }}</p></vs-td>
                                </vs-tr>
                                <vs-tr>
                                    <vs-td>
                                        <p class="font-medium"><strong>Total</strong></p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="product-category"><strong>$ {{ data.total_general }}</strong></p>
                                    </vs-td>
                                </vs-tr>
                            </tbody>
                        </template>
                    </vs-table>
                </vs-row>
                <vs-row>
                    <vs-table :model="this.tillSales[0]" ref="table" :data="tillSales[0]">  
                        <!-- <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                            <h2>Resumen de Ventas</h2>
                        </div> -->
                        <template slot="thead">
                            <vs-th sort-key="id"></vs-th>
                            <vs-th sort-key="name">Importe</vs-th>
                            <vs-th sort-key="name">En Caja</vs-th>
                            <vs-th sort-key="name">Diferencia</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <tbody>
                                <vs-tr>
                                    <vs-td><p class="font-medium">Total Efectivo</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.total_efectivo }}</p></vs-td>
                                    <vs-td><p class="font-medium">$ {{ data.total_efectivo + data.diferencia }}</p></vs-td>
                                    <vs-td><p class="font-medium" :style="data.diferencia != 0 ? 'color: red' : ''">$ {{ data.diferencia }}</p></vs-td>
                                </vs-tr>
                            </tbody>
                        </template>
                    </vs-table>
                </vs-row>
            </div>
            <!-- Sales by product -->
            <div :class="tillSales.length > 0 ? 'vx-col sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mb-4' : 'vx-col w-full'" style="float: left">
                <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
                    <vs-table v-if="this.tillDetail.length > 0" :model="this.tillDetail" ref="table" :data="tillDetail">  
                        <template slot="thead">
                            <vs-th sort-key="id">Producto</vs-th>
                            <vs-th sort-key="name">Cantidad</vs-th>
                            <vs-th sort-key="description">Precio</vs-th>
                            <vs-th sort-key="products">Total</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <tbody>
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                                    <vs-td>
                                        <p class="font-medium">{{ tr.product_name }}</p>
                                    </vs-td>

                                    <vs-td>
                                        <p class="product-name font-medium truncate">{{ tr.qty }}</p>
                                    </vs-td>

                                    <vs-td>
                                        <p class="product-category">$ {{ tr.price }}</p>
                                    </vs-td>

                                    <vs-td>
                                        <p class="product-category">$ {{ tr.sale_total }}</p>
                                    </vs-td>

                                    <!-- <vs-td>
                                        <span>{{tr.stock}}</span>
                                        <vs-progress :percent="(Number(tr.stock) > 0) ? Number(tr.stock * 10) : 0"  :color="getPopularityColor(Number(tr.stock))" class="shadow-md" />
                                    </vs-td>

                                    <vs-td>
                                        <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{ tr.status === 0 ? "Inactivo" : "Activo" }}</vs-chip>
                                    </vs-td> -->

                                    <!-- <vs-td>
                                        <p class="product-price">${{ tr.price }}</p>
                                    </vs-td> -->

                                </vs-tr>
                                <vs-tr>
                                    <vs-td>
                                        <p class="font-medium"><strong>Total</strong></p>
                                    </vs-td>
                                    <vs-td>
                                        <p class="product-category"><strong>{{data.reduce((total, s) => total + s.qty, 0)}}</strong></p>
                                    </vs-td>
                                    <vs-td>
                                    </vs-td>
                                    <vs-td>
                                        <p class="product-category"><strong>$ {{data.reduce((total, s) => total + s.sale_total, 0).toFixed(2)}}</strong></p>
                                    </vs-td>
                                </vs-tr>
                            </tbody>
                        </template>
                    </vs-table>
                </vs-row>
            </div>
        </div>
        <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" style="margin-bottom: 5px">
            <vs-button v-if="tillSales.length > 0" class="mb-4 md:mb-0" @click="print()">Imprimir</vs-button>
        </vs-row>
      </vs-popup>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Object,
                default: () => {},
            },
            isPopupActive: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                popupActive: false,
                tillSales: [],
                tillDetail: [],
            }
        },
        computed: {
            isPopupActiveLocal: {
                get() {
                    return this.isPopupActive
                },
                set(val) {
                    if(!val) {
                        this.$emit('closePopup')
                    }
                }
            },
        },
        watch: {
            isPopupActive(val) {
            if(!val) return
            if(Object.entries(this.data).length === 0) {
                this.initValues()
            }else {
                this.tillSales = JSON.parse(JSON.stringify(this.data.sales))
                this.tillDetail = JSON.parse(JSON.stringify(this.data.products))
            }
            // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
            }
        },
        methods: {
            initValues() {
                this.tillSales = []
                this.tillDetail = []
            },
            print() {
                const vhtpOptions = {
                    name: '_blank',
                    specs: [
                        'fullscreen=yes',
                        'titlebar=yes',
                        'scrollbars=yes'
                    ],
                    styles: ['https://cdn.jsdelivr.net/npm/vuesax/dist/vuesax.css',]
                }
                this.$htmlToPaper('printArea', vhtpOptions);
            }
        }
    }
</script>
<style>
    .vs-popup--content { 
        height:100% !important;
    }
    .tr-values:last-child {
        border-top: 1px solid black;
    }
</style>
